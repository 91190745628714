export const styles = {
  checkbox: { marginRight: 0 },

  listItem: {
    padding: "4px",
  },

  resetButton: {
    width: "100%",
  },

  listWrapper: {
    gap: "12px",
    padding: "0",
  },

  footer: {
    gap: "16px",
    display: "flex",
    columnGap: "24px",
  },

  chipTrigger: {
    maxWidth: "50%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  drawerPaper: {
    height: "unset",
    maxHeight: "50vh",
    borderRadius: "16px 16px 0 0",
  },

  chipsWrapper: {
    gap: "8px",
    display: "flex",
    // overflowX: "scroll",
    padding: "0 0 12px 0",
  },
};
