import { mainTheme } from "@/shared/ui/styles";

export const styles = {
  drawerTitle: {
    fontSize: "16px",
    marginBottom: "24px",
  },

  form: {
    padding: 0,
    background: "unset",

    [mainTheme.breakpoints.down("md")]: {
      padding: 0,
    },
  },

  innerWrapper: {
    maxWidth: "350px",
    minHeight: "unset",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
};
