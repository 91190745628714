import { FC } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import {
  $mealListByType,
  $roomsListByType,
  $filtersByMealType,
  $filtersByRoomType,
  handleFilterChange,
  handleFiltersReset,
} from "@/shared/model/store/search";
import {
  EFilterType,
  TChangeValue,
  TFilterOptions,
} from "@/shared/model/types/tours";
import { CustomChip } from "@/shared/ui";

type TRenderCustomChipsProps = {
  type: EFilterType;
  listByType: TFilterOptions[];
  filters: (string | number)[];
  onChange: (value: TChangeValue) => void;
};

const RenderCustomChips: FC<TRenderCustomChipsProps> = ({
  type,
  filters,
  onChange,
  listByType,
}) => {
  const t = useTranslations();

  return (
    <>
      {filters.map((value) => {
        const label = listByType.find((item) => item.value === value)?.label;

        return (
          <CustomChip
            key={value}
            size={"medium"}
            label={t(label)}
            sx={styles.chip}
            onDelete={() =>
              onChange({
                type,
                value,
              })
            }
          />
        );
      })}
    </>
  );
};

export const FilteredRoomsNotFound: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [
    mealListByType,
    roomsListByType,
    filtersByMealType,
    filtersByRoomType,
    handleFiltersResetFn,
    handleFilterChangeFn,
  ] = useUnit([
    $mealListByType,
    $roomsListByType,
    $filtersByMealType,
    $filtersByRoomType,
    handleFiltersReset,
    handleFilterChange,
  ]);

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.title}>{t("NO_TOURS_FILTERS")}</Typography>

      <Box sx={styles.filtersWrapper}>
        <Typography
          // TODO: problem with theme types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          variant="small"
          sx={styles.filtersTitle}
        >
          {t("CANCEL_AT_LEAST_ONE_FILTER")}
          <Condition match={isDesktop}>:</Condition>
        </Typography>

        <Condition match={isDesktop}>
          <Box sx={styles.filtersChipsWrapper}>
            <RenderCustomChips
              type={EFilterType.Room}
              filters={filtersByRoomType}
              listByType={roomsListByType}
              onChange={handleFilterChangeFn}
            />

            <RenderCustomChips
              type={EFilterType.Meal}
              listByType={mealListByType}
              filters={filtersByMealType}
              onChange={handleFilterChangeFn}
            />
          </Box>
        </Condition>

        <Box>
          <Button size="small" onClick={() => handleFiltersResetFn()}>
            {t("SHOW_ALL_TOURS_HOTEL")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
