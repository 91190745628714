import { mainTheme } from "shared/ui/styles";

import { createGradient } from "@/shared/lib/helpers";

const gradient = createGradient();

export const styles = {
  swiperButtonLeft: {
    left: "12px",
  },

  swiperButtonRight: {
    right: "12px",
  },

  swiper: {
    inset: 0,
    zIndex: 0,
    height: "100%",
    position: "absolute",
  },

  wrapper: {
    width: "200px",
    display: "flex",
    minHeight: "180px",
    overflow: "hidden",
    position: "relative",
  },

  image: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    userSelect: "none",
    objectFit: "cover",
    WebkitUserDrag: "none",
    objectPosition: "center",
  },

  swiperButton: {
    zIndex: 1,
    opacity: 0,
    top: "50%",
    position: "absolute",
    pointerEvents: "none",
    transform: "translateY(-50%)",
    transition: "opacity .3s ease",
  },

  imagePlaceholderContainer: {
    display: "flex",
    position: "relative",
    placeContent: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "grey.white",
  },

  swiperSlide: {
    flexShrink: 0,
    height: "auto",

    favoriteIcon: {
      top: "4.17%",
      right: "4.17%",
      bottom: "12.5%",
      cursor: "pointer",
      position: "absolute",
    },
  },

  imageContainer: (customBreakPoint?: number) => ({
    width: "200px",
    flex: "0 0 auto",
    ...gradient,

    [mainTheme.breakpoints.down(customBreakPoint ?? "smd")]: {
      height: "220px",
      width: "stretch",
    },
  }),

  defaultImageWrapper: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    color: "unset",
    display: "flex",
    fill: "transparent",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "grey.white",
  },

  swiperContainer: {
    position: "relative",

    "&:hover .swiper-button": {
      opacity: "1 !important",
      pointerEvents: "auto !important",
    },

    "& .swiper-pagination-bullet-active": {
      width: "14px",
      borderRadius: "100px",
      backgroundColor: "dominant.white",
    },

    "& .swiper-pagination-bullet": {
      opacity: 1,
      backgroundColor: "rgba(255,255,255, 0.6)",
      transition: "width .3s, background-color .4s",
    },
  },
};
