/* eslint-disable perfectionist/sort-objects */
import { createApi, createStore } from "effector";

export const $isContactsModalOpen = createStore(false);

export const { openContactsModal, closeContactsModal } = createApi(
  $isContactsModalOpen,
  {
    openContactsModal: () => true,
    closeContactsModal: () => false,
  },
);
