import { useRouter, useSearchParams } from "next/navigation";
import { FC, useState } from "react";

import { Button, Collapse, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import isEmpty from "lodash/isEmpty";
import { useTranslations } from "next-intl";

import {
  openContactsModal,
  closeContactsModal,
  $isContactsModalOpen,
} from "../../model";
import { AvailableRoomCard } from "../available-room-card";
import { styles } from "./styles";

import { LeadDialog } from "@/features/lead-form";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { $filteredToursCombinedAsRooms } from "@/shared/model/store/search";
import { CollapseButton, TourNotFoundSimple } from "@/shared/ui";

export const RoomsList: FC = () => {
  const t = useTranslations();
  const router = useRouter();
  const searchParams = useSearchParams();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const [isShowAllCards, setShowAllCards] = useState(false);
  const [filteredToursCombinedAsRooms, isOpen, handleOpenFn, handleCloseFn] =
    useUnit([
      $filteredToursCombinedAsRooms,

      $isContactsModalOpen,
      openContactsModal,
      closeContactsModal,
    ]);
  const roomsLength = Object.keys(filteredToursCombinedAsRooms ?? {}).length;

  const handleBackToSearch = () => {
    router.push(`/search?${searchParams?.toString()}`);
  };

  if (isEmpty(filteredToursCombinedAsRooms)) {
    return (
      <TourNotFoundSimple>
        <Button variant="contained" onClick={handleBackToSearch}>
          {t("BACK_TO_TOUR_SELECTION")}
        </Button>

        <LeadDialog isOpen={isOpen} onClose={handleCloseFn} />

        <Button variant="outlined" onClick={() => handleOpenFn()}>
          {t("LEAVE_CONTACTS")}
        </Button>
      </TourNotFoundSimple>
    );
  }

  return (
    <>
      <AvailableRoomCard
        index={0}
        isPhone={isPhone}
        allRoomsInfo={Object.values(filteredToursCombinedAsRooms)[0]}
      />

      {roomsLength > 1 && (
        <Collapse in={isShowAllCards}>
          {Object.entries(filteredToursCombinedAsRooms)
            .slice(1, roomsLength)
            .map(([key, value], index) => (
              <AvailableRoomCard
                key={key}
                index={index + 1}
                isPhone={isPhone}
                allRoomsInfo={value}
              />
            ))}
        </Collapse>
      )}

      <Condition match={Object.keys(filteredToursCombinedAsRooms).length > 1}>
        <CollapseButton
          fullWidth
          variant="outlined"
          sx={styles.hideButton}
          isOpen={isShowAllCards}
          id="hotel_show_more_btn_test"
          onClick={() => setShowAllCards(!isShowAllCards)}
        />
      </Condition>
    </>
  );
};
