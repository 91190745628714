export const mobileStyles = {
  priceWrapper: {},

  divider: {
    margin: "12px 0",
  },

  wrapper: {
    marginTop: "16px",
  },

  title: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
  },

  textBox: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },

  textWrapper: {
    rowGap: "8px",
    display: "flex",
    flexDirection: "column",
  },

  tourInfoWrapper: {
    rowGap: "12px",
    display: "flex",
    flexDirection: "column",
  },

  tourInfoText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "content.secondary",
  },

  imageContainer: {
    width: "70px",
    height: "70px",
    overflow: "hidden",
    borderRadius: "8px",
  },

  infoWrapper: {
    display: "flex",
    columnGap: "12px",
    marginTop: "12px",
    alignItems: "center",
  },

  moreInfoButton: {
    height: "18px",
    padding: "0 !important",

    "&:hover": {
      backgroundColor: "unset",
    },
  },
};
