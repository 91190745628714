import { FC } from "react";

import { Box, SxProps, PaperProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { SimpleLeadForm } from "../simple-lead-form";
import { styles } from "./styles";

import { DialogWrapper } from "@/shared/ui";

type TProps = {
  title?: string;
  isOpen: boolean;
  source?: string;
  onClose?: () => void;
  WrapperProps?: SxProps;
  onSuccess?: () => void;
  PaperProps?: PaperProps;
  formWrapperSx?: SxProps;
  innerWrapperSx?: SxProps;
  formDescription?: string;
  Footer?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
};

export const LeadDialog: FC<TProps> = ({
  isOpen,
  source,
  Footer,
  onClose,
  children,
  onSuccess,
  PaperProps,
  WrapperProps,
  formWrapperSx,
  innerWrapperSx,
  formDescription,
  title = "Оставьте Ваши данные",
}) => {
  const t = useTranslations();

  return (
    <DialogWrapper
      title={title}
      open={isOpen}
      onClose={onClose}
      PaperProps={PaperProps}
      WrapperProps={WrapperProps}
    >
      <Box sx={{ ...styles.innerWrapper, ...innerWrapperSx }}>
        {children ? (
          children
        ) : (
          <Typography sx={styles.drawerTitle}>
            {t("A_MANAGER_WILL_CALL_YOU_SOON")}
          </Typography>
        )}

        <SimpleLeadForm
          source={source}
          description={formDescription}
          onSuccess={onSuccess ?? onClose}
          wrapperSx={{ ...styles.form, ...formWrapperSx }}
        />

        {Footer && Footer}
      </Box>
    </DialogWrapper>
  );
};
