import { mainTheme } from "shared/ui/styles";

export const styles = {
  icon: {
    fontSize: 16,
    marginRight: "8px",
  },

  paperSx: {
    maxWidth: "768px",
    width: "100% !important",
  },

  textWrapper: (isPhone: boolean) => ({
    width: isPhone ? "unset" : "720px",
  }),

  amenitiesTitle: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
  },

  subtitle: {
    fontSize: "14px",
    color: "grey-400",
    lineHeight: "16px",
    marginBottom: "24px",
  },

  divider: {
    marginTop: "-24px",
    marginBottom: "24px",

    [mainTheme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },

  text: {
    width: "90%",
    whiteSpace: "nowrap",
    color: "content.secondary",
    [mainTheme.breakpoints.down("md")]: {
      whiteSpace: "wrap",
    },
  },

  imageWrapper: {
    height: "110px",
    overflow: "hidden",
    borderRadius: "8px",

    [mainTheme.breakpoints.down("sm")]: {
      height: "160px",
    },
  },

  singleAmenityWrapper: {
    width: "100%",
    display: "flex",
    maxWidth: "212px",
    overflow: "hidden",
    alignItems: "center",
    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "none",
    },
  },

  amenitiesWrapper: {
    rowGap: "8px",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      maxHeight: "unset",
      flexShrink: "none",
    },
  },

  tag: {
    padding: "4px 6px",
    borderRadius: "4px",
    marginBottom: "24px",
    display: "inline-block",
    color: "accent.brand.dark",
    backgroundColor: "accent.brand.lightest",

    [mainTheme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },
};
