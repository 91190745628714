import { useRouter, useSearchParams } from "next/navigation";
import { FC, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { priceFormatter } from "@/shared/lib/helpers";
import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { getInitialSearchQuery } from "@/shared/model/api/search";
import { TPaymentPlan, QUERY_OPTIONS } from "@/shared/model/types/common";
import { LoaderButton } from "@/shared/ui";
import { DeferredPayment } from "@/shared/ui";

type TProps = {
  price: number;
  index?: number;
  tourId: string;
  fullPrice: number;
  onSelectTour: () => void;
  paymentPlan: null | TPaymentPlan;
};

export const RoomSelectBlock: FC<TProps> = ({
  index,
  price,
  tourId,
  fullPrice,
  paymentPlan,
  onSelectTour,
}) => {
  const t = useTranslations();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [isCreditTooltipOpen, setCreditTooltipOpen] = useState(false);

  const { pending: isPageLoading } = useUnit(getInitialSearchQuery);

  const handleTooltipOpen = () => {
    setCreditTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setCreditTooltipOpen(false);
  };

  const handleSelectTour = (id: string) => {
    onSelectTour();

    const childrenAgesParams = searchParams?.getAll(QUERY_OPTIONS.childrenAges);
    const arrival = searchParams?.get(QUERY_OPTIONS.arrival);
    const departure = searchParams?.get(QUERY_OPTIONS.departure);

    const childrenAges = childrenAgesParams
      ? {
          [QUERY_OPTIONS.childrenAges]: childrenAgesParams,
        }
      : {};

    const query = getSearchParamsString({
      [QUERY_OPTIONS.arrival]: arrival,
      [QUERY_OPTIONS.departure]: departure,
      ...childrenAges,
    });

    router.push(`/polety/${id}?${query}`);
  };

  return (
    <Box sx={styles.priceWrapper}>
      <Box sx={styles.innerPriceWrapper}>
        <Typography sx={styles.priceText}>
          {priceFormatter(price)}
          {price < fullPrice && (
            <Typography sx={styles.oldPrice}>
              {priceFormatter(fullPrice)}
            </Typography>
          )}
        </Typography>

        <Condition match={Boolean(paymentPlan)}>
          <DeferredPayment
            open={isCreditTooltipOpen}
            onMouseUp={handleTooltipClose}
            onMouseDown={handleTooltipOpen}
            onMouseEnter={handleTooltipOpen}
            onMouseLeave={handleTooltipClose}
            currency={paymentPlan?.currency ?? "KZT"}
            paymentPerMonth={paymentPlan?.amount ?? 0}
            onClick={() => setCreditTooltipOpen(!isCreditTooltipOpen)}
          />
        </Condition>
      </Box>
      <LoaderButton
        variant="contained"
        loading={isPageLoading}
        onClick={() => handleSelectTour(tourId)}
        id={`hotel_choose_room_btn_${index}_test`}
      >
        {t("SELECT")}
      </LoaderButton>
    </Box>
  );
};
