export const styles = {
  infoText: {
    color: "content.secondary",
  },

  title: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
  },

  wrapper: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },

  select: {
    gap: "10px",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  info: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
    justifyContent: "space-between",
  },
};
