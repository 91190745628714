import { mainTheme } from "shared/ui/styles";

export const styles = {
  rates: {
    mb: "50px",
    "& .th-rating-review-block": {
      padding: "0",
    },
  },

  dialogWrapper: {
    width: "922px",
    maxHeight: "60vh",
    [mainTheme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  wrapperProps: {
    overflowY: "scroll",
    "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
      height: "fit-content",
    },
  },

  reviewsTrigger: {
    padding: 0,
    width: "100%",
    height: "auto",
    marginTop: "20px",
    [mainTheme.breakpoints.up("md")]: {
      "button > div": {
        justifyContent: "flex-start",
      },
    },
    "div p": {
      marginLeft: "16px",
      [mainTheme.breakpoints.up("md")]: {
        marginLeft: 0,
      },
    },
  },
};
