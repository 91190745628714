import dayjs from "dayjs";

import { THotelData } from "@/shared/model/types/common";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";

export const getDistanceFromAirport = (hotelData: null | THotelData) => {
  if (!hotelData) return "";

  let distanceFromAirportAmenity;

  if (hotelData.descriptions && Array.isArray(hotelData.descriptions)) {
    distanceFromAirportAmenity = hotelData.descriptions
      .flatMap((item) => item.amenities)
      .find((amenity) => amenity.code === "do-aeroporta-km");

    if (distanceFromAirportAmenity && distanceFromAirportAmenity.description) {
      return distanceFromAirportAmenity.description;
    }
  }

  if (hotelData.amenities && Array.isArray(hotelData.amenities)) {
    distanceFromAirportAmenity = hotelData.amenities.find(
      (amenity) => amenity.code === "do-aeroporta-km",
    );

    if (distanceFromAirportAmenity && distanceFromAirportAmenity.description) {
      return distanceFromAirportAmenity.description;
    }
  }

  return "";
};

export const generateTableData = (allRooms: TToursCombinedAsRooms[]) =>
  allRooms.map((roomData) => {
    const {
      id,
      tags,
      room,
      meal,
      price,
      nights,
      check_in,
      check_out,
      full_price,
      tour_group,
      flight_type,
      nights_total,
      payment_plan,
      tour_operator,
    } = roomData;

    return {
      id,
      tags,
      flightType: flight_type,
      tourOperator: tour_operator,
      airlines: tour_group.airlines ?? [],
      meal: {
        name: meal.name,
        description: meal.description,
      },
      price: {
        price,
        fullPrice: full_price,
        paymentPlan: payment_plan,
      },
      dates: {
        nights,
        nights_total,
        date_to: check_out,
        date_from: check_in,
      },
      capacity: {
        adults: room.adults,
        infants: room.infants,
        childrenAmount: room.children,
      },
    };
  });

export const generateTourDatesText = (dateFrom: string, dateTo: string) => {
  const conditionalDateFormat =
    dayjs(dateFrom).format("MM") === dayjs(dateTo).format("MM")
      ? "DD"
      : "DD MMM";

  return `${dayjs(dateFrom).format(conditionalDateFormat)} - ${dayjs(
    dateTo,
  ).format("DD MMM")}`;
};

export const generateDataForMobileHeader = (hotelData: null | THotelData) => {
  return {
    images: hotelData?.images ?? [],
    videos: hotelData?.videos ?? [],
    hotelName: hotelData?.name ?? "",
    hotelCategory: hotelData?.category,
    hotelRating: hotelData?.rating ?? 0,
    reviewsCount: hotelData?.reviews_count,
  };
};
