import { FC } from "react";

import { RoomModalContent } from "./room-modal-content";
import { styles } from "./styles";

import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { DialogWrapper } from "@/shared/ui";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  firstRoomInfo: TToursCombinedAsRooms;
  openSliderModal: (v?: number) => void;
};

export const RoomDetailsModal: FC<TProps> = ({
  isOpen,
  onClose,
  firstRoomInfo,
  openSliderModal,
}) => {
  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      title={firstRoomInfo.room.name}
      PaperProps={{ sx: styles.paperSx }}
    >
      <RoomModalContent
        roomInfo={firstRoomInfo}
        openSliderModal={openSliderModal}
      />
    </DialogWrapper>
  );
};
