import { FC, SyntheticEvent } from "react";

import { Phone, Person } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";
import { useForm } from "effector-forms";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { SOURCE_FOR_MUTATION } from "../../lib";
import { $$simpleLeadForm, SimpleLeadFormGate } from "../../model";
import { styles } from "./styles";

import { postLeadToCRMMutation } from "@/shared/model/api/common";
import { NewInput, Agreement, NewInputMask, LoaderButton } from "@/shared/ui";

type TProps = {
  source?: string;
  wrapperSx?: SxProps;
  withLabels?: boolean;
  description?: string;
  onSuccess?: () => void;
  withAgreement?: boolean;
  title?: string | JSX.Element;
};

export const SimpleLeadForm: FC<TProps> = ({
  title,
  onSuccess,
  wrapperSx,
  description,
  withLabels = true,
  withAgreement = true,
  source = SOURCE_FOR_MUTATION,
}) => {
  const t = useTranslations();

  useGate(SimpleLeadFormGate, { source, onSuccess, description });

  const { fields, submit, errorText } = useForm($$simpleLeadForm);
  const { pending: isLoading } = useUnit(postLeadToCRMMutation);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    submit();
  };

  return (
    <Box sx={{ ...styles.formContainer, ...wrapperSx }}>
      {title}

      <form onSubmit={handleSubmit}>
        <Box sx={styles.formWrapper}>
          <NewInputMask
            mask="+7 999 999-99-99"
            value={fields.phone.value}
            placeholder="+7 (___) ___-__-__"
            errorMessage={t(errorText("phone"))}
            label={t(withLabels ? "PHONE" : "")}
            onChange={(e) => fields.phone.onChange(e.target.value)}
            endAdornment={<Phone color="disabled" fontSize="small" />}
          />
          <NewInput
            value={fields.name.value}
            placeholder={t("YOUR_NAME")}
            errorMessage={t(errorText("name"))}
            label={withLabels ? t("NAME") : ""}
            onChange={(e) => fields.name.onChange(e.target.value)}
            endAdornment={<Person fontSize="small" color="disabled" />}
          />
          <LoaderButton
            fullWidth
            size="large"
            type="submit"
            loading={isLoading}
            variant="contained"
          >
            {t("SEND")}
          </LoaderButton>
        </Box>
      </form>
      {withAgreement && <Agreement sx={styles.agreement} />}
    </Box>
  );
};
