import { FC } from "react";

import { Person } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TProps = {
  adults: number;
  infants: number;
  isPhone?: boolean;
  childrenAmount: number;
};

type TSingleCapacityProps = {
  amount: number;
  isChild?: boolean;
};

const SingleCapacity: FC<TSingleCapacityProps> = ({
  amount = 0,
  isChild = false,
}) => {
  if (amount <= 3) {
    return (
      <Box sx={styles.touristWrapper}>
        {Array.from({ length: amount }, (_, index) => (
          <Person key={index} fontSize={isChild ? "small" : "medium"} />
        ))}
      </Box>
    );
  }

  return (
    <Box sx={styles.touristWrapper}>
      <Person fontSize={isChild ? "small" : "medium"} />
      <Typography sx={styles.personText}> × {amount}</Typography>
    </Box>
  );
};

export const RoomCapacity: FC<TProps> = ({
  adults = 0,
  infants = 0,
  isPhone = false,
  childrenAmount = 0,
}) => {
  const t = useTranslations();

  if (adults + childrenAmount + infants === 0) {
    return <>{isPhone ? "-" : t("NOT_SPECIFIED")}</>;
  }

  return (
    <Box sx={styles.touristWrapper}>
      <SingleCapacity amount={adults} />
      <Condition match={childrenAmount + infants > 0}>
        <Typography sx={styles.plusSign}>+</Typography>
        <SingleCapacity isChild amount={childrenAmount + infants} />
      </Condition>
    </Box>
  );
};
