export const styles = {
  hideButton: {
    display: "flex",
    columnGap: "8px",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  downIcon: {
    transition: "transform 0.2s",

    rotateIcon: {
      transform: "rotate(180deg)",
      transition: "transform 0.2s",
    },
  },
};
