import { FC, useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { RoomCapacity } from "../room-capacity";
import { RoomModalContent } from "../room-modal";
import { RoomTags } from "../room-tags";
import { mobileStyles } from "./styles";

// TODO: not compatible with fsd imports
import { generateTourDatesText } from "@/pagesLayer/oteli/[hotelSlug]/lib/helpers";

import { RoomSelectBlock } from "@/features/hotels";

import { Condition } from "@/shared/lib/condition";
import { getFlightTypeText } from "@/shared/lib/helpers";
import { useGetDeclinations } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { Image, SwipeableDrawer, RoundedBorderContainer } from "@/shared/ui";

import AirplaneIcon from "@/public/icons/common/airplane_outlined.svg";
import CalendarIcon from "@/public/icons/common/calendar.svg";
import MealIcon from "@/public/icons/common/restaurant.svg";
import SupprtIcon from "@/public/icons/common/support.svg";

type TProps = {
  index?: number;
  roomInfo: TToursCombinedAsRooms;
};

export const MobileAvailableRoomCard: FC<TProps> = ({ index, roomInfo }) => {
  const t = useTranslations();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const { nightsTotalText } = useGetDeclinations({
    nights: roomInfo.nights,
    nightsTotal: roomInfo.nights_total,
  });

  const { room } = roomInfo;

  const [isInfoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const isAdditionalInfo = room.amenities.length > 0 || room.images.length > 0;

  const airlines = roomInfo.tour_group.airlines;
  const airlinesText = airlines?.[0]?.name
    ? `${airlines[0].name}${
        airlines.length > 1 ? `, +${airlines.length - 1}` : ""
      }`
    : "-";

  return (
    <>
      <RoundedBorderContainer>
        <RoomTags isPhone tags={roomInfo.tags} />
        <Typography sx={mobileStyles.title}>{room.name}</Typography>
        <Box sx={mobileStyles.infoWrapper}>
          <Box sx={mobileStyles.imageContainer}>
            <Image width={70} height={70} src={room.images[0]?.src} />
          </Box>
          <Box sx={mobileStyles.textWrapper}>
            <Box sx={mobileStyles.textBox}>
              <Typography>{t("SIZE_SQM")}:</Typography>
              <Typography>{room.area ? `${room.area} м²` : "-"}</Typography>
            </Box>
            <Box sx={mobileStyles.textBox}>
              <Typography>{`${t("CAPACITY")}:`}</Typography>
              <RoomCapacity
                isPhone
                adults={room.adults}
                infants={room.infants}
                childrenAmount={room.children}
              />
            </Box>
            <Button
              variant="text"
              disabled={!isAdditionalInfo}
              sx={mobileStyles.moreInfoButton}
              onClick={() => {
                sendDataLayerEventFn({
                  event: "aboutRoomClick",
                  data: {
                    clickText: "Подробнее о номере",
                  },
                });

                setInfoDrawerOpen(true);
              }}
            >
              {isAdditionalInfo ? t("ROOM_DETAILS") : t("NO_ADDITIONAL_INFO")}
            </Button>
          </Box>
        </Box>

        <Divider sx={mobileStyles.divider} />
        <Box sx={mobileStyles.tourInfoWrapper}>
          <Box sx={mobileStyles.textBox}>
            <CalendarIcon width="16px" height="16px" />
            <Typography
              sx={mobileStyles.tourInfoText}
            >{`${nightsTotalText}, ${generateTourDatesText(
              roomInfo.check_in,
              roomInfo.check_out,
            )}`}</Typography>
          </Box>
          <Box sx={mobileStyles.textBox}>
            <MealIcon width="16px" height="16px" />
            <Typography sx={mobileStyles.tourInfoText}>
              {roomInfo.meal.name}
            </Typography>
          </Box>

          <Condition match={Boolean(airlines && airlines.length > 0)}>
            <Box sx={mobileStyles.textBox}>
              <AirplaneIcon width="16px" height="16px" />
              <Typography sx={mobileStyles.tourInfoText}>
                {airlinesText}
              </Typography>
            </Box>
          </Condition>

          <Condition match={!airlines?.length}>
            <Box sx={mobileStyles.textBox}>
              <AirplaneIcon width="16px" height="16px" />
              <Typography sx={mobileStyles.tourInfoText}>
                {t(getFlightTypeText(roomInfo.flight_type))}
              </Typography>
            </Box>
          </Condition>

          <Box sx={mobileStyles.textBox}>
            <SupprtIcon width="16px" height="16px" />
            <Typography sx={mobileStyles.tourInfoText}>
              {t("TOUR_OPERATOR")} {roomInfo.tour_operator.name}
            </Typography>
          </Box>
        </Box>
        <Divider sx={mobileStyles.divider} />

        <RoomSelectBlock
          index={index}
          tourId={roomInfo.id}
          price={roomInfo.price}
          fullPrice={roomInfo.full_price}
          paymentPlan={roomInfo.payment_plan}
          onSelectTour={() => {
            sendDataLayerEventFn({
              event: "roomChoise",
              data: {
                roomInfo,
              },
            });
          }}
        />
      </RoundedBorderContainer>

      <Condition match={isAdditionalInfo}>
        <SwipeableDrawer
          onOpen={() => null}
          title={t("ABOUT_ROOM")}
          isOpen={isInfoDrawerOpen}
          onClose={() => setInfoDrawerOpen(false)}
        >
          <RoomModalContent isPhone roomInfo={roomInfo} />
        </SwipeableDrawer>
      </Condition>
    </>
  );
};
