import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  nameInput: {
    padding: "13.5px 12px",
  },

  phoneInputWrapper: {
    height: "48px",
  },

  formWrapper: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },

  agreement: {
    marginTop: "16px",
    textAlign: "center",
    color: "content.secondary",
  },

  formContainer: {
    padding: "20px",
    borderRadius: "16px",
    backgroundColor: "dominant.cloud",

    [mainTheme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
};
