import { FC, useMemo, useState, useCallback } from "react";

import { Check } from "@mui/icons-material";
import { Box, Modal, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { RoomDetailsModal } from "../room-modal";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { SWIPER_MODAL_STYLES } from "@/shared/lib/constants";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { Image, TourSlider } from "@/shared/ui";

import ImageIcon from "@/public/icons/common/image.svg";
import RoomSizeIcon from "@/public/icons/common/room_size.svg";

type TProps = {
  firstRoomInfo: TToursCombinedAsRooms;
};

const DEFAULT_AMENITY = {
  id: "default",
  name: "AMENITIES_NOT_SPECIFIED",
};

const SLICE_AMOUNT = 2;

export const GeneralRoomInfo: FC<TProps> = ({ firstRoomInfo }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const t = useTranslations();
  const { room } = firstRoomInfo;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const openSliderModal = useCallback((index?: number) => {
    setSlideIndex(index ?? 0);
    setSliderOpen(true);
  }, []);
  const closeSliderModal = useCallback(() => setSliderOpen(false), []);

  const openInfoModal = useCallback(() => {
    sendDataLayerEventFn({
      event: "aboutRoomClick",
      data: { clickText: "Подробнее о номере" },
    });

    setModalOpen(true);
  }, [sendDataLayerEventFn]);
  const closeInfoModal = useCallback(() => setModalOpen(false), []);

  const isImagesAvailable = room.images.length > 0;
  const isAdditionalInfo = room.amenities.length > 0 || isImagesAvailable;

  const slicedAmenities = useMemo(() => {
    const { amenities } = room;

    if (amenities.length === 0) {
      return [DEFAULT_AMENITY];
    }

    return amenities.slice(0, SLICE_AMOUNT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room.amenities]);

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box>
          <Typography sx={styles.title}>{firstRoomInfo.room.name}</Typography>
          <Box sx={styles.amenitiesWrapper}>
            <Box sx={styles.singleAmenityWrapper}>
              <RoomSizeIcon width={17} height={17} style={styles.icon} />
              <Typography sx={styles.text}>
                {room.area ? `${room.area} м²` : t("SIZE_NOT_SPECIFIED")}
              </Typography>
            </Box>

            {slicedAmenities.map((amenity) => (
              <Box key={amenity.id} sx={styles.singleAmenityWrapper}>
                <Check color="inherit" sx={styles.icon} />
                <Typography sx={styles.text}>{t(amenity.name)}</Typography>
              </Box>
            ))}

            <Condition match={isAdditionalInfo}>
              <Button
                variant="text"
                onClick={openInfoModal}
                sx={styles.detailsButton}
              >
                {t("ROOM_DETAILS")}
              </Button>
            </Condition>
          </Box>
        </Box>

        <Condition match={isImagesAvailable}>
          <Button sx={styles.imagesWrapper} onClick={() => openSliderModal(0)}>
            {room.images.slice(0, 3).map((img) => (
              <Image
                rounded
                width={86}
                height={64}
                key={img.id}
                src={img.src}
                alt={room.name}
                imageWrapperSx={{ width: "86px" }}
              />
            ))}

            <Box sx={styles.imageCounterBox}>
              <ImageIcon width="20px" height="20px" />
              <Typography>{room.images.length}</Typography>
            </Box>
          </Button>
        </Condition>
      </Box>

      <Condition match={isAdditionalInfo}>
        <RoomDetailsModal
          isOpen={isModalOpen}
          onClose={closeInfoModal}
          firstRoomInfo={firstRoomInfo}
          openSliderModal={openSliderModal}
        />
      </Condition>

      <Condition match={isImagesAvailable}>
        <Modal
          open={isSliderOpen}
          sx={SWIPER_MODAL_STYLES}
          onClose={closeSliderModal}
        >
          <TourSlider
            mediaArray={room.images}
            initialSlide={slideIndex}
            onClose={closeSliderModal}
          />
        </Modal>
      </Condition>
    </>
  );
};
