import { memo, useCallback } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { MobileRoomsFilters } from "../Mobile";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import {
  $mealListByType,
  $roomsListByType,
  $filtersByMealType,
  $filtersByRoomType,
  handleFilterChange,
  handleFiltersReset,
  $filteredToursCombinedAsRooms,
} from "@/shared/model/store/search";
import { EFilterType, TChangeValue } from "@/shared/model/types/tours";
import { MultiSelect } from "@/shared/ui";

export const RoomsFilter = memo(() => {
  const [
    mealListByType,
    roomsListByType,
    filtersByMealType,
    filtersByRoomType,
    filteredToursCombinedAsRooms,
    handleFiltersResetFn,
    handleFilterChangeFn,
  ] = useUnit([
    $mealListByType,
    $roomsListByType,
    $filtersByMealType,
    $filtersByRoomType,
    $filteredToursCombinedAsRooms,
    handleFiltersReset,
    handleFilterChange,
  ]);

  const roomsLength = Object.keys(filteredToursCombinedAsRooms ?? {}).length;

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const handleAnalyticsSend = useCallback(
    (value: string | number) => {
      const { label } =
        roomsListByType.find((room) => room.value === value) ?? {};

      sendDataLayerEventFn({
        event: "filtrSelecttourClick",
        data: {
          choiceFiltr: label,
        },
      });
    },
    [roomsListByType, sendDataLayerEventFn],
  );

  const handleChange = useCallback(
    (data: TChangeValue) => {
      handleAnalyticsSend(data.value);

      handleFilterChangeFn(data);
    },
    [handleAnalyticsSend, handleFilterChangeFn],
  );

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h4" sx={styles.title}>
        {t("CHOOSE_TOUR")}
      </Typography>

      <Condition match={isPhone}>
        <MobileRoomsFilters
          meals={mealListByType}
          rooms={roomsListByType}
          onChange={handleChange}
          filtersByMealType={filtersByMealType}
          filtersByRoomType={filtersByRoomType}
          handleFiltersReset={handleFiltersResetFn}
        />
      </Condition>

      <Condition match={!isPhone}>
        <Box sx={styles.select}>
          <MultiSelect
            value={filtersByRoomType}
            id="hotel_page_room_filter"
            placeholder={t("ROOM_TYPE")}
            options={roomsListByType.map((room) => ({
              ...room,
              label: t(room.label),
            }))}
            onChange={(value) =>
              handleChange({
                value,
                type: EFilterType.Room,
              })
            }
          />

          <MultiSelect
            options={mealListByType}
            value={filtersByMealType}
            id="hotel_page_meal_filter"
            placeholder={t("MEAL_TYPE")}
            onChange={(value) =>
              handleChange({
                value,
                type: EFilterType.Meal,
              })
            }
          />
        </Box>

        <Box sx={styles.info}>
          <Typography component="span" sx={styles.infoText}>
            <Condition match={Boolean(roomsLength)}>
              {t("FOUND_TOURS_DECLINATIONS", { count: roomsLength })}
            </Condition>

            <Condition match={!roomsLength}>{t("TOURS_NOT_FOUND")}</Condition>
          </Typography>

          <Button size="small" onClick={() => handleFiltersResetFn()}>
            {t("CLEAR_FILTERS")}
          </Button>
        </Box>
      </Condition>
    </Box>
  );
});
