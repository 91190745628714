import { mainTheme } from "shared/ui/styles";

import { TCountriesTags } from "@/shared/model/types/common";

export const styles = {
  wrapper: {
    gap: "10px",
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "250px",
    alignItems: "center",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "100%",
      marginBottom: "12px",
    },
  },

  tag: (tag: TCountriesTags) => ({
    fontSize: "10px",
    display: "inline",
    lineHeight: "12px",
    padding: "3px 6px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    color: tag.meta.font_color,
    backgroundColor: tag.meta.bg_color,

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "16px",
      padding: "4px 6px",
    },
  }),
};
