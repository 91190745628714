import { FC } from "react";

import { Check } from "@mui/icons-material";
import { Box, Divider, SxProps, Typography, ButtonProps } from "@mui/material";
import { useTranslations } from "next-intl";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, useSwiper, SwiperSlide } from "swiper/react";

import { styles } from "./styles";

import { styles as swiperStyles } from "@/entities/banner-carousel/ui/styles";

import { Condition } from "@/shared/lib/condition";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { Image, SliderButton, TooltipWithTextSlice } from "@/shared/ui";

type TProps = {
  isPhone?: boolean;
  roomInfo: TToursCombinedAsRooms;
  openSliderModal?: (v?: number) => void;
};

const SwiperPrevButton: FC<ButtonProps> = (props) => {
  const swiper = useSwiper();

  return (
    <SliderButton arrow="left" {...props} onClick={() => swiper.slidePrev()} />
  );
};
const SwiperNextButton: FC<ButtonProps> = (props) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="right" {...props} onClick={() => swiper.slideNext()} />
  );
};

export const RoomModalContent: FC<TProps> = ({
  roomInfo,
  openSliderModal,
  isPhone = false,
}) => {
  const t = useTranslations();
  const { room } = roomInfo;

  return (
    <>
      <Condition match={Boolean(room.description)}>
        <Typography sx={styles.subtitle}>{room.description}</Typography>
      </Condition>

      <Condition match={Boolean(room.area)}>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <Box sx={styles.tag}>{`${room.area!} м²`}</Box>
      </Condition>

      <Condition match={room.images.length > 0}>
        <Box sx={swiperStyles.carousel}>
          <Box
            sx={
              {
                // TODO: spread these styles between banner carousel and room modal
                ...swiperStyles.swiperContainer,
                height: "unset",
              } as SxProps
            }
          >
            <Swiper
              loop
              className="mySwiper"
              spaceBetween={isPhone ? 0 : 8}
              slidesPerView={isPhone ? 1 : 4}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {room.images.map(({ id, src }, index) => (
                <SwiperSlide key={id} virtualIndex={index}>
                  <Image
                    rounded
                    src={src}
                    imageWrapperSx={styles.imageWrapper}
                    onClick={() => openSliderModal?.(index)}
                  />
                </SwiperSlide>
              ))}
              <SwiperPrevButton className="swiper-button swiper-button-prev" />
              <SwiperNextButton className="swiper-button swiper-button-next" />
            </Swiper>
          </Box>
        </Box>
        <Divider sx={styles.divider} />
      </Condition>

      <Condition match={room.amenities.length > 0}>
        <Box sx={styles.textWrapper(isPhone)}>
          <Typography sx={styles.amenitiesTitle}>
            {t("ROOM_AMENITIES")}
          </Typography>
          <Box sx={styles.amenitiesWrapper}>
            {room.amenities.map((amenity) => (
              <Box key={amenity.id} sx={styles.singleAmenityWrapper}>
                <Check color="success" sx={styles.icon} />
                <TooltipWithTextSlice
                  title={amenity.name}
                  textSx={styles.text}
                  sliceAt={!isPhone ? 19 : 1000}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Condition>
    </>
  );
};
