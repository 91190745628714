export const styles = {
  mealCell: {
    maxWidth: "180px",
  },

  stickyLeftRow: {
    left: 0,
    position: "sticky",
  },

  stickyRightRow: {
    right: 0,
    position: "sticky",
  },

  mealTitle: {
    fontSize: "16px",
    lineHeight: "18px",
  },

  nightsText: {
    fontSize: "16px",
    lineHeight: "18px",
  },

  adultsText: {
    fontSize: "20px",
    lineHeight: "24px",
  },

  childrenText: {
    fontSize: "18px",
    lineHeight: "18px",
  },

  touristWrapper: {
    display: "flex",
    alignItems: "center",
  },

  mealIconWrapper: {
    height: "16px",
    color: "grey.extralight",
  },

  header: {
    position: "relative",
    backgroundColor: "dominant.dop",
  },

  row: {
    border: "1px solid",
    borderColor: "accent.brand.lightest",
  },

  container: {
    width: "100%",
    border: "none",
    maxWidth: "100%",
  },

  mealWrapper: {
    display: "flex",
    columnGap: "5px",
    alignItems: "center",
  },

  allAirlines: {
    rowGap: "10px",
    display: "flex",
    flexDirection: "column",
  },

  airlineWrapper: {
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
  },

  datesText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "content.grey-400",
  },

  cellBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },

  cell: {
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: "accent.brand.lightest",
  },

  flexContainer: {
    display: "flex",
    columnGap: "5px",
    alignItems: "center",

    "& svg": {
      color: "primary.main",
    },
  },

  wrapper: {
    minWidth: 950,
    overflow: "hidden",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: "accent.brand.lightest",
    borderCollapse: "separate !important",
  },
};
