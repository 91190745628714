import { COLORS, mainTheme } from "shared/ui/styles";

export const styles = {
  carouselContainer: {
    px: "16px",
  },

  wrapper: {
    width: "100%",
    height: "100%",
  },

  skeleton: {
    width: "100%",
    height: "200px",
    marginBottom: "36px",
  },

  carousel: {
    width: "100%",
    overflow: "hidden",
    marginBottom: "24px",
    paddingBottom: "40px",

    [mainTheme.breakpoints.down("md")]: {
      height: "unset",
      paddingBottom: "50px",
    },
  },

  swiperContainer: {
    height: "300px",
    overflow: "hidden",
    position: "relative",
    borderRadius: "16px",

    "& .swiper-pagination": {
      pointerEvents: "none",
    },

    [mainTheme.breakpoints.down("smd")]: {
      height: "164px",
    },

    swiperLink: {
      width: "100%",
      backgroundColor: "cloud",
    },

    "& .swiper-pagination-bullets": {
      bottom: "-29px !important",
    },

    "& .swiper-pagination-bullet-active": {
      width: "14px",
      borderRadius: "100px",
      backgroundColor: "grey.light",
    },

    "& .swiper-pagination-bullet": {
      opacity: 1,
      backgroundColor: "grey.white",
      pointerEvents: "auto !important",
      transition: "width .3s, background-color .4s",
    },

    "& .mySwiper": {
      zIndex: 0,
      width: "100%",
      height: "100%",
      cursor: "pointer",
      overflow: "visible",
      pointerEvents: "auto !important",
      "& .swiper-button-prev": {
        left: "16px",
      },
      "& .swiper-button-next": {
        right: "16px",
      },

      "&:hover .swiper-button": {
        opacity: "70% !important",
        transition: "opacity .2s",
        "&:hover": {
          opacity: "1 !important",
        },
      },

      "@media screen and (min-width: 960px)": {
        "&:hover": {
          "&:after": {
            opacity: "10%",
            transition: "opacity .2s",
          },
        },
      },

      "&:after": {
        inset: 0,
        zIndex: 1,
        opacity: 0,
        content: "''",
        maxHeight: "300px",
        borderRadius: "17px",
        position: "absolute",
        pointerEvents: "none",
        transition: "opacity .2s",
        backgroundColor: "grey.dark",
      },

      "& .swiper-button": {
        zIndex: 2,
        padding: 0,
        top: "50%",
        width: "42px",
        height: "42px",
        display: "flex",
        minWidth: "20px",
        minHeight: "20px",
        borderRadius: "10px",
        position: "absolute",
        alignItems: "center",
        opacity: "0 !important",
        justifyContent: "center",
        transition: "opacity .2s",
        transform: "translate(0, -50%)",
        backgroundColor: "secondary.main",

        "&:hover path": {
          stroke: COLORS.BLUE,
        },

        "@media screen and (max-width: 600px)": {
          display: "none",
        },
      },
    },
  },
};
