export const styles = {
  icon: {
    fontSize: 16,
    marginRight: "8px",
  },

  amenitiesWrapper: {
    display: "flex",
    columnGap: "16px",
  },

  wrapper: {
    display: "flex",
    marginBottom: "24px",
    justifyContent: "space-between",
  },

  singleAmenityWrapper: {
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
  },

  title: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    marginBottom: "20px",
  },

  text: {
    width: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "content.secondary",
  },

  detailsButton: {
    padding: "0",
    height: "unset",
    transition: "0.2s",
    alignSelf: "flex-start",

    "&:hover": {
      opacity: "0.7",
      backgroundColor: "unset",
    },
  },

  imagesWrapper: {
    padding: 0,
    height: "64px",
    display: "flex",
    columnGap: "12px",
    transition: "0.2s",
    width: "fit-content",
    alignItems: "center",
    position: "relative",

    "&:hover": {
      opacity: "0.7",
    },
  },

  imageCounterBox: {
    right: "6px",
    bottom: "6px",
    display: "flex",
    columnGap: "4px",
    padding: "4px 8px",
    borderRadius: "4px",
    position: "absolute",
    alignItems: "center",
    color: "dominant.white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};
