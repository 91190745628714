export const styles = {
  main: {
    height: "50px",
  },

  mainBox: {
    marginTop: "10px",
  },

  chip: {
    width: "100%",
    height: "40px",
  },

  chipBox: {
    gap: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
};
