import { mainTheme } from "shared/ui/styles";

export const styles = {
  collapse: {
    width: "100%",
  },

  wrapper: {
    padding: "24px",
    marginBottom: "24px",
  },

  collapseButton: {
    display: "flex",
    columnGap: "8px",
    marginTop: "16px",
    marginLeft: "auto",
  },

  mobileWrapper: {
    marginTop: "16px",

    [mainTheme.breakpoints.down("sm")]: {
      marginTop: "4px",
    },
  },

  grid: {
    display: "flex",
    columnGap: "16px",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  flex: {
    display: "flex",
    columnGap: "16px",
    marginTop: "20px",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
