/* eslint-disable perfectionist/sort-objects */
import { sample, createEffect, EventCallable } from "effector";
import { createForm } from "effector-forms";
import { createGate } from "effector-react";

import {
  PHONE_REGEX,
  DEFAULT_ERROR,
  REQUIRED_FIELD_ERROR,
  CORRECT_PHONE_ERROR_TEXT,
} from "@/shared/lib/constants";
import { checkValidationRule } from "@/shared/lib/helpers";
import { postLeadToCRMMutation } from "@/shared/model/api/common";
import { $userLeadFormData } from "@/shared/model/store/auth";
import { openNotification } from "@/shared/model/store/notifications";
import { TNotice } from "@/shared/model/types/common";

type TSendLeadInfoFormData = {
  name: string;
  phone: string;
};

export const SimpleLeadFormGate = createGate<{
  source: string;
  description?: string;
  onSuccess?: () => void;
}>();

const onSuccessFx = createEffect((callback?: () => void) => callback?.());

export const $$simpleLeadForm = createForm<TSendLeadInfoFormData>({
  fields: {
    name: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) => Boolean(value),
          errorText: REQUIRED_FIELD_ERROR,
        },
      ],
    },
    phone: {
      init: "",
      rules: [
        {
          name: "required",
          validator: (value: string) =>
            Boolean(value) && checkValidationRule(value, PHONE_REGEX),
          errorText: CORRECT_PHONE_ERROR_TEXT,
        },
      ],
    },
  },
  validateOn: ["submit"],
});

sample({
  clock: SimpleLeadFormGate.open,
  source: $userLeadFormData,
  fn: (data) => ({
    phone: data?.phone ?? "",
    name: data?.name ?? "",
  }),
  target: $$simpleLeadForm.setForm as EventCallable<TSendLeadInfoFormData>,
});

sample({
  clock: $$simpleLeadForm.formValidated,
  source: {
    form: $$simpleLeadForm.$values,
    details: SimpleLeadFormGate.state,
  },
  fn: ({ form, details }) => ({
    ...form,
    source: details.source,
    description: details.description,
  }),
  target: postLeadToCRMMutation.start,
});

sample({
  clock: postLeadToCRMMutation.finished.success,
  fn: () =>
    ({
      severity: "success",
      message: "DATA_SUCCESSFULLY_SENT",
    }) as TNotice,
  target: [openNotification, $$simpleLeadForm.reset as EventCallable<void>],
});

sample({
  clock: postLeadToCRMMutation.finished.success,
  source: SimpleLeadFormGate.state,
  fn: ({ onSuccess }) => onSuccess,
  target: onSuccessFx,
});

sample({
  clock: postLeadToCRMMutation.finished.failure,
  fn: ({ error }) =>
    ({
      severity: "error",
      message: error.message ?? DEFAULT_ERROR,
    }) as TNotice,
  target: openNotification,
});
