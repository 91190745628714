import { FC, memo } from "react";

import { Box, SxProps } from "@mui/material";
import { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, useSwiper, SwiperSlide } from "swiper/react";

import { styles } from "./styles";

import { THotelImages } from "@/shared/model/types/common";
import { Image, SliderButton } from "@/shared/ui";
import { EBreakpoints } from "@/shared/ui/styles/theme/types";

type TSwiperButtonProps = {
  style: SxProps;
  className: string;
};

type TProps = {
  sx?: SxProps;
  onClick: () => void;
  images: THotelImages[];
  HotelTags?: JSX.Element;
  LikeButton?: JSX.Element;
  customBreakPoint?: EBreakpoints;
};

const swiperButtonStylesLeft = {
  ...styles.swiperButton,
  ...styles.swiperButtonLeft,
};

const swiperButtonStylesRight = {
  ...styles.swiperButton,
  ...styles.swiperButtonRight,
};

const SwiperPrevButton = (props: TSwiperButtonProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton {...props} arrow="left" onClick={() => swiper.slidePrev()} />
  );
};
const SwiperNextButton = (props: TSwiperButtonProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="right" {...props} onClick={() => swiper.slideNext()} />
  );
};

export const HotelImageSwiper: FC<TProps> = memo(
  ({
    sx = {},
    onClick,
    HotelTags,
    LikeButton,
    images = [],
    customBreakPoint,
  }: TProps) => {
    return (
      <Box sx={{ ...styles.wrapper, ...sx } as SxProps}>
        {images.length ? (
          <Box
            sx={
              {
                ...styles.imageContainer(customBreakPoint),
                ...styles.swiperContainer,
              } as SxProps
            }
          >
            <Swiper
              virtual
              allowTouchMove
              // @ts-expect-error
              style={styles.swiper}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination, Virtual]}
            >
              {images.map((item, index) => (
                <SwiperSlide
                  key={item.id}
                  onClick={onClick}
                  virtualIndex={index}
                  style={styles.swiperSlide}
                >
                  <Image
                    alt={item.id}
                    src={item.src}
                    imageWrapperSx={styles.image}
                  />
                </SwiperSlide>
              ))}

              {images.length > 1 && (
                <>
                  <SwiperPrevButton
                    className="swiper-button"
                    style={swiperButtonStylesLeft}
                  />

                  <SwiperNextButton
                    className="swiper-button"
                    style={swiperButtonStylesRight}
                  />
                </>
              )}
            </Swiper>
            {HotelTags}
            {LikeButton}
          </Box>
        ) : (
          <Box
            sx={
              {
                ...styles.imageContainer(customBreakPoint),
                ...styles.imagePlaceholderContainer,
              } as SxProps
            }
          >
            <Image
              onClick={onClick}
              imageWrapperSx={styles.defaultImageWrapper}
            />
            {HotelTags}
            {LikeButton}
          </Box>
        )}
      </Box>
    );
  },
);
