import { FC, useState } from "react";

import { Box, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { HotelsReviewsScripts } from "./HotelReviewsScripts";
import { ReviewsSkeleton } from "./ReviewsSkeleton";
import { styles } from "./styles";

import { THotelReviewsProps } from "@/features/hotels/HotelReviews/model/types";

import { TOP_HOTELS_API_KEY } from "@/shared/config";
import { useCustomTheme } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { AdaptiveDrawer, SupportActionCard } from "@/shared/ui";

import Comments from "@/public/icons/common/comments.svg";

const Content: FC<{ hotelId: string }> = ({ hotelId }) => {
  return (
    <>
      <Box
        sx={styles.rates}
        // hardcoded id from TopHotels API
        id={`th_rating_default_${TOP_HOTELS_API_KEY}_modal`}
      >
        <ReviewsSkeleton />
      </Box>

      <Box
        // hardcoded id from TopHotels API
        id={`th_rates_ver8_${TOP_HOTELS_API_KEY}_default`}
      >
        <ReviewsSkeleton />
      </Box>

      <HotelsReviewsScripts hotelId={hotelId} />
    </>
  );
};

export const HotelReviews: FC<THotelReviewsProps> = ({ hotelId }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const t = useTranslations();

  const [open, setOpen] = useState(false);
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("smd"));

  const handleClick = () => {
    sendDataLayerEventFn({
      event: "allFeedback",
      data: {
        clickText: "Все отзывы",
      },
    });

    setOpen(true);
  };

  return (
    <>
      <AdaptiveDrawer
        fullHeight
        isOpen={open}
        isPhone={isPhone}
        title={t("REVIEWS")}
        onClose={() => setOpen(false)}
        desktopSx={styles.dialogWrapper}
        Content={<Content hotelId={hotelId} />}
      />

      <SupportActionCard
        iconSize="small"
        text={t("ALL_FROM")}
        onClick={handleClick}
        isSeparate={!isDesktop}
        wrapperSx={styles.reviewsTrigger}
        icon={isDesktop ? undefined : <Comments />}
      />
    </>
  );
};
