import { mainTheme } from "shared/ui/styles";

export const styles = {
  chip: {
    width: "fit-content",
  },

  filtersTitle: {
    color: "content.secondary",
  },

  title: {
    fontWeight: 500,
    fontSize: "20px",
  },

  filtersChipsWrapper: {
    gap: "12px",
    display: "flex",
    flexWrap: "wrap",
  },

  filtersWrapper: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },

  wrapper: {
    mt: "24px",
    gap: "20px",
    padding: "24px",
    display: "flex",
    border: "1px solid",
    borderRadius: "16px",
    flexDirection: "column",
    borderColor: "secondary.main",
    backgroundColor: "dominant.white",

    [mainTheme.breakpoints.down("md")]: {
      mt: "4px",
    },
  },
};
