/* eslint-disable @typescript-eslint/no-non-null-assertion */
//  TopHotels Rates

import { TOP_HOTELS_API_KEY, TOP_HOTELS_API_URL } from "@/shared/config";

export const getHotelRatesScript = (hotelId: string) => {
  return `
    window.__tophotelsWidgetData__rates__ver8__default__ = {
      request: {host: '${TOP_HOTELS_API_URL}', path: '/rates/ver8/', params: {
              uid: '${TOP_HOTELS_API_KEY}', mode: 'default'
      }},
      allocation: ${hotelId}
  };
    `;
};

export const setHotelRatesDOMScript = `(function(){if(typeof __tophotelsWidgetData__rates__ver8__default__!="undefined"){var t,i=window.__tophotelsWidgetData__rates__ver8__default__,n,u=function(n){var i=[];for(var t in n)n.hasOwnProperty(t)&&i.push(encodeURIComponent(t)+"="+encodeURIComponent(n[t]));return i.join("&")},f=function(n,t){var i=window.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP");t=t||{};i.open(t.method||"GET",n,!0);i.setRequestHeader("Content-type","application/x-www-form-urlencoded");i.setRequestHeader("Accept","text/html");i.onreadystatechange=function(){i.readyState===4&&(i.status===200&&t.success?t.success(i):i.status!==200&&t.error&&t.error(i))};i.send(t.data||"")},e=function(){return i.request.host+i.request.path+(typeof i.allocation!="undefined"?i.allocation:"")},r=function(n){document.getElementById(n.placeId).innerHTML=n.html;for(var t in n.call)n.call.hasOwnProperty(t)&&(s=document.createElement("script"),s.innerHTML=n.call[t],document.getElementById(n.placeId).appendChild(s))};f(e()+"?"+u(i.request.params),{success:function(u){var f,e;if(n=JSON.parse(u.response),f=0,typeof n.css!="undefined"&&n.css.length>0)for(e in n.css)n.css.hasOwnProperty(e)&&(t=document.createElement("link"),t.setAttribute("rel","stylesheet"),t.setAttribute("type","text/css"),t.setAttribute("href",i.request.host+n.css[e]),t.addEventListener("load",function(){return function(){f++;f===n.css.length&&r(n)}}(t)),document.getElementsByTagName("head")[0].appendChild(t));else r(n)},error:function(n){console.log("Code: "+n.status+" Message: "+n.statusText)}})}})();`;

//  TopHotels Rating

export const getHotelRatingScript = (hotelId: string) => {
  return `
    window.__tophotelsWidgetData__rating__default__default__ = {
        request: {host: '${TOP_HOTELS_API_URL}', path: '/rating/default/', params: {
                uid: '${TOP_HOTELS_API_KEY}', mode: 'default'
        }},
        allocation: ${hotelId}
    };
    `;
};

export const setHotelRatingDOMScript = `(function(){if(typeof __tophotelsWidgetData__rating__default__default__!="undefined"){var t,i=window.__tophotelsWidgetData__rating__default__default__,n,u=function(n){var i=[];for(var t in n)n.hasOwnProperty(t)&&i.push(encodeURIComponent(t)+"="+encodeURIComponent(n[t]));return i.join("&")},f=function(n,t){var i=window.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP");t=t||{};i.open(t.method||"GET",n,!0);i.setRequestHeader("Content-type","application/x-www-form-urlencoded");i.setRequestHeader("Accept","text/html");i.onreadystatechange=function(){i.readyState===4&&(i.status===200&&t.success?t.success(i):i.status!==200&&t.error&&t.error(i))};i.send(t.data||"")},e=function(){return i.request.host+i.request.path+(typeof i.allocation!="undefined"?i.allocation:"")},r=function(n){document.getElementById(n.placeId).innerHTML=n.html;for(var t in n.call)n.call.hasOwnProperty(t)&&(s=document.createElement("script"),s.innerHTML=n.call[t],document.getElementById(n.placeId).appendChild(s))};f(e()+"?"+u(i.request.params),{success:function(u){var f,e;if(n=JSON.parse(u.response),f=0,typeof n.css!="undefined"&&n.css.length>0)for(e in n.css)n.css.hasOwnProperty(e)&&(t=document.createElement("link"),t.setAttribute("rel","stylesheet"),t.setAttribute("type","text/css"),t.setAttribute("href",i.request.host+n.css[e]),t.addEventListener("load",function(){return function(){f++;f===n.css.length&&r(n)}}(t)),document.getElementsByTagName("head")[0].appendChild(t));else r(n)},error:function(n){console.log("Code: "+n.status+" Message: "+n.statusText)}})}})();`;

export const getHotelRatingModalScript = (hotelId: string) => {
  return `
  window.__tophotelsWidgetData__rating__default__modal__ = { 
        request: {host: '${TOP_HOTELS_API_URL}', path: '/rating/default/', params: {
            uid: '${TOP_HOTELS_API_KEY}', mode: 'modal'
        }},
        allocation: ${hotelId}
  };
  `;
};

export const setHotelRatingModalDOMScript = `(function(){if(typeof __tophotelsWidgetData__rating__default__modal__!="undefined"){var t,i=window.__tophotelsWidgetData__rating__default__modal__,n,u=function(n){var i=[];for(var t in n)n.hasOwnProperty(t)&&i.push(encodeURIComponent(t)+"="+encodeURIComponent(n[t]));return i.join("&")},f=function(n,t){var i=window.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP");t=t||{};i.open(t.method||"GET",n,!0);i.setRequestHeader("Content-type","application/x-www-form-urlencoded");i.setRequestHeader("Accept","text/html");i.onreadystatechange=function(){i.readyState===4&&(i.status===200&&t.success?t.success(i):i.status!==200&&t.error&&t.error(i))};i.send(t.data||"")},e=function(){return i.request.host+i.request.path+(typeof i.allocation!="undefined"?i.allocation:"")},r=function(n){document.getElementById(n.placeId).innerHTML=n.html;for(var t in n.call)n.call.hasOwnProperty(t)&&(s=document.createElement("script"),s.innerHTML=n.call[t],document.getElementById(n.placeId).appendChild(s))};f(e()+"?"+u(i.request.params),{success:function(u){var f,e;if(n=JSON.parse(u.response),f=0,typeof n.css!="undefined"&&n.css.length>0)for(e in n.css)n.css.hasOwnProperty(e)&&(t=document.createElement("link"),t.setAttribute("rel","stylesheet"),t.setAttribute("type","text/css"),t.setAttribute("href",i.request.host+n.css[e]),t.addEventListener("load",function(){return function(){f++;f===n.css.length&&r(n)}}(t)),document.getElementsByTagName("head")[0].appendChild(t));else r(n)},error:function(n){console.log("Code: "+n.status+" Message: "+n.statusText)}})}})();`;
