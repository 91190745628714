import { FC, useState, useCallback } from "react";

import { Box, Button, ListItem } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { CHECKED_ALL } from "@/shared/lib/constants";
import {
  EFilterType,
  TChangeValue,
  TFilterOptions,
} from "@/shared/model/types/tours";
import { CustomChip, CustomCheckbox, SwipeableDrawer } from "@/shared/ui";

type TProps = {
  meals: TFilterOptions[];
  rooms: TFilterOptions[];
  filtersByMealType: (string | number)[];
  filtersByRoomType: (string | number)[];
  onChange: (value: TChangeValue) => void;
  handleFiltersReset: (type?: EFilterType) => void;
};

type TFiltersDrawer = {
  id?: string;
  title: string;
  isOpen: boolean;
  type: EFilterType;
  onReset: () => void;
  onClose: () => void;
  options: TFilterOptions[];
  value: (string | number)[];
  onOpen: (type: EFilterType) => void;
  onChange: (value: TChangeValue) => void;
};

const FiltersDrawer: FC<TFiltersDrawer> = ({
  id,
  type,
  value,
  title,
  onOpen,
  isOpen,
  options,
  onReset,
  onClose,
  onChange,
}) => {
  const t = useTranslations();

  return (
    <SwipeableDrawer
      closeButton
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      hideBackdrop={false}
      onOpen={() => onOpen(type)}
      paperSx={styles.drawerPaper}
      Footer={
        <>
          <Box sx={styles.footer}>
            <Button fullWidth variant="text" onClick={onReset}>
              {t("RESET")}
            </Button>

            <Button fullWidth onClick={onClose} variant="contained">
              {t("APPLY")}
            </Button>
          </Box>
        </>
      }
    >
      <Box sx={styles.listWrapper}>
        {options.map((item, index) => (
          <ListItem
            key={item.value}
            value={item.value}
            sx={styles.listItem}
            id={`${id}_${index}_test`}
            onClick={() =>
              onChange({
                type,
                value: item.value,
              })
            }
          >
            <CustomCheckbox
              sx={styles.checkbox}
              checked={value.includes(item.value)}
            />
            {t(item.label)}
          </ListItem>
        ))}
      </Box>
    </SwipeableDrawer>
  );
};

export const MobileRoomsFilters: FC<TProps> = ({
  meals,
  rooms,
  onChange,
  filtersByMealType,
  filtersByRoomType,
  handleFiltersReset,
}) => {
  const t = useTranslations();
  const [isMealsFilterOpen, setIsMealsFilterOpen] = useState(false);
  const [isRoomsFilterOpen, setIsRoomsFilterOpen] = useState(false);

  const handleFilterToggle = useCallback((type: EFilterType) => {
    setIsRoomsFilterOpen(type === EFilterType.Room);
    setIsMealsFilterOpen(type === EFilterType.Meal);
  }, []);

  const handleFilterClose = useCallback(() => {
    setIsRoomsFilterOpen(false);
    setIsMealsFilterOpen(false);
  }, []);

  const commonProps = {
    onChange,
    onClose: handleFilterClose,
    onReset: handleFiltersReset,
  };

  const getFilteredOptions = (
    options: TFilterOptions[],
    filters: (string | number)[],
  ) => {
    return options
      .filter((option) => filters.includes(option.value))
      .map(({ label }) => label);
  };

  const getChipText = useCallback(
    (type: EFilterType) => {
      if (type === EFilterType.Room) {
        const filteredRooms = getFilteredOptions(rooms, filtersByRoomType);

        if (filteredRooms.length > 0) {
          const remainingRooms = filtersByRoomType.length - 1;

          return `${filteredRooms[0]}${
            remainingRooms > 0 ? `, + ${remainingRooms}` : ""
          }`;
        }

        return t("ROOM_TYPES");
      } else {
        const filteredMeals = getFilteredOptions(meals, filtersByMealType);

        if (filteredMeals.length > 0) {
          const remainingMeals = filtersByMealType.length - 1;

          return `${filteredMeals[0]}${
            remainingMeals > 0 ? `, + ${remainingMeals}` : ""
          }`;
        }

        return t("MEAL_TYPES");
      }
    },
    [rooms, filtersByRoomType, meals, filtersByMealType, t],
  );

  const handleDelete = useCallback(
    (type: EFilterType) => {
      if (type === EFilterType.Room) {
        return !filtersByRoomType.filter((item) => item !== CHECKED_ALL).length
          ? undefined
          : () => handleFiltersReset(EFilterType.Room);
      }

      return !filtersByMealType.filter((item) => item !== CHECKED_ALL).length
        ? undefined
        : () => handleFiltersReset(EFilterType.Meal);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersByMealType, filtersByRoomType],
  );

  return (
    <>
      <FiltersDrawer
        options={meals}
        type={EFilterType.Meal}
        title={t("MEAL_TYPES")}
        value={filtersByMealType}
        isOpen={isMealsFilterOpen}
        id="hotel_page_meal_filter"
        onOpen={handleFilterToggle}
        {...commonProps}
      />

      <FiltersDrawer
        options={rooms}
        type={EFilterType.Room}
        title={t("ROOM_TYPES")}
        value={filtersByRoomType}
        isOpen={isRoomsFilterOpen}
        id="hotel_page_room_filter"
        onOpen={handleFilterToggle}
        {...commonProps}
      />

      <Box sx={styles.chipsWrapper}>
        <CustomChip
          sx={styles.chipTrigger}
          label={t(getChipText(EFilterType.Room))}
          onDelete={handleDelete(EFilterType.Room)}
          onClick={() => {
            handleFilterToggle(EFilterType.Room);
          }}
        />
        <CustomChip
          sx={styles.chipTrigger}
          label={t(getChipText(EFilterType.Meal))}
          onDelete={handleDelete(EFilterType.Meal)}
          onClick={() => {
            handleFilterToggle(EFilterType.Meal);
          }}
        />
      </Box>
    </>
  );
};
