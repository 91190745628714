export const styles = {
  person: {
    marginBottom: "3px",
  },

  personText: {
    fontSize: "14px",
    lineHeight: "16px",
  },

  touristWrapper: {
    display: "flex",
    alignItems: "center",
  },

  plusSign: {
    fontSize: "14px",
    marginLeft: "6px",
    lineHeight: "16px",
  },

  childrenWrapper: {
    display: "flex",
    marginLeft: "5px",
    alignItems: "center",
  },
};
