import Script from "next/script";
import { FC } from "react";

import {
  getHotelRatesScript,
  getHotelRatingScript,
  setHotelRatesDOMScript,
  setHotelRatingDOMScript,
  getHotelRatingModalScript,
  setHotelRatingModalDOMScript,
} from "@/features/hotels/HotelReviews/lib/scripts";

type TProps = {
  hotelId: string;
  ratingOnly?: boolean;
};

export const HotelsReviewsScripts: FC<TProps> = ({
  hotelId,
  ratingOnly = false,
}) =>
  ratingOnly ? (
    <>
      {/* TopHotels Rating  */}
      <Script id="getRating">{getHotelRatingScript(hotelId)}</Script>
      <Script id="setRating">{setHotelRatingDOMScript}</Script>
    </>
  ) : (
    <>
      {/* TopHotels Rates  */}
      <Script id="getRates">{getHotelRatesScript(hotelId)}</Script>
      <Script id="setRates">{setHotelRatesDOMScript}</Script>

      {/* TopHotels Rating  */}
      <Script id="getModalRating">{getHotelRatingModalScript(hotelId)}</Script>
      <Script id="setModalRating">{setHotelRatingModalDOMScript}</Script>
    </>
  );
