import { FC } from "react";

import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { TCountriesTags } from "@/shared/model/types/common";

type TProps = {
  isPhone?: boolean;
  tags: TCountriesTags[];
};

export const RoomTags: FC<TProps> = ({ tags = [], isPhone = false }) => {
  return (
    <>
      <Condition match={Boolean(tags.length)}>
        <Box sx={styles.wrapper}>
          {tags.slice(0, 2).map((tag) => (
            <Typography key={tag.id} sx={styles.tag(tag)}>
              {tag.name}
            </Typography>
          ))}
        </Box>
      </Condition>

      <Condition match={!tags.length && !isPhone}>-</Condition>
    </>
  );
};
