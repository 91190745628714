import { Box, Skeleton } from "@mui/material";
import * as CSS from "csstype";

import { styles } from "./styles";

const AMOUNT_OF_SKELETONS = 3;

export const ReviewsSkeleton = () => {
  return (
    <>
      <Box sx={styles.chipBox as CSS.Properties}>
        <Skeleton sx={styles.chip} />
      </Box>

      <Box sx={styles.mainBox as CSS.Properties}>
        {Array.from({ length: AMOUNT_OF_SKELETONS }, (_, index) => (
          <Skeleton key={index} sx={styles.main} />
        ))}
      </Box>
    </>
  );
};
