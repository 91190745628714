export const styles = {
  innerPriceWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  priceWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  priceText: {
    display: "flex",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    alignItems: "center",
  },

  oldPrice: {
    fontSize: "14px",
    marginLeft: "8px",
    lineHeight: "16px",
    color: "accent.red.error",
    textDecoration: "line-through",
  },
};
