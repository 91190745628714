import { FC, useMemo, useState } from "react";

import { Box, Collapse } from "@mui/material";
import { useTranslations } from "next-intl";

import { AvailableRoomsTable } from "../available-rooms-table";
import { GeneralRoomInfo } from "../general-room-info";
import { MobileAvailableRoomCard } from "../mobile-available-room-card";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { CollapseButton, RoundedBorderContainer } from "@/shared/ui";

type TProps = {
  index?: number;
  isPhone: boolean;
  allRoomsInfo: TToursCombinedAsRooms[];
};

const SLICE_AMOUNT = 2;

export const AvailableRoomCard: FC<TProps> = ({
  index,
  isPhone = false,
  allRoomsInfo = [],
}) => {
  const t = useTranslations();
  const [isCollapseOpen, setCollapseOpen] = useState(false);

  const slicedRoomInfo = useMemo(() => {
    if (allRoomsInfo.length > SLICE_AMOUNT && !isCollapseOpen) {
      return allRoomsInfo.slice(0, SLICE_AMOUNT);
    }

    return allRoomsInfo;
  }, [isCollapseOpen, allRoomsInfo]);

  const collapsedSize = useMemo(
    () => (allRoomsInfo.length === 1 ? "200px" : "240px"),
    [allRoomsInfo.length],
  );

  if (!allRoomsInfo.length) {
    return null;
  }

  const firstRoomInfo = allRoomsInfo[0];

  if (isPhone) {
    return (
      <Box sx={styles.mobileWrapper}>
        {allRoomsInfo.map((room, index) => (
          <MobileAvailableRoomCard
            key={room.id}
            index={index}
            roomInfo={room}
          />
        ))}
      </Box>
    );
  }

  return (
    <RoundedBorderContainer sx={styles.wrapper}>
      <Box sx={styles.grid}>
        <GeneralRoomInfo firstRoomInfo={firstRoomInfo} />
        <Box sx={styles.collapse}>
          <Collapse
            in={isCollapseOpen}
            sx={styles.collapse}
            collapsedSize={collapsedSize}
          >
            <AvailableRoomsTable allRooms={slicedRoomInfo} />
          </Collapse>

          <Condition match={allRoomsInfo.length - SLICE_AMOUNT > 0}>
            <CollapseButton
              variant="text"
              isOpen={isCollapseOpen}
              sx={styles.collapseButton}
              id={`hotel_show_more_tours_btn_${index}_test`}
              onClick={() => setCollapseOpen(!isCollapseOpen)}
            >
              {isCollapseOpen
                ? t("HIDE")
                : `${t("SHOW_MORE")} ${t("TOUR_DECLINATIONS", { count: allRoomsInfo.length - SLICE_AMOUNT })}`}
            </CollapseButton>
          </Condition>
        </Box>
      </Box>
    </RoundedBorderContainer>
  );
};
