import { FC } from "react";

import { SxProps } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { amenitiesRenderer } from "@/shared/lib/helpers";
import { TAmenity } from "@/shared/model/types/common";
import { AmenityBadge } from "@/shared/ui";

type TProps = {
  id: string;
  textSx?: SxProps;
  amenities: TAmenity[];
};

export const HotelAmenities: FC<TProps> = ({
  id,
  textSx = {},
  amenities = [],
}) => {
  const t = useTranslations();

  if (!amenities.length) {
    return (
      <AmenityBadge sx={styles.amenityBadge} icon={amenitiesRenderer("")}>
        {t("AMENITIES_NOT_SPECIFIED")}
      </AmenityBadge>
    );
  }

  return (
    <>
      {amenities.map((el, index) => (
        <AmenityBadge
          textSx={textSx}
          sx={styles.amenityBadge}
          key={`${id}-${el.id}-${index}`}
          icon={amenitiesRenderer(el.code)}
        >
          {el.name}
        </AmenityBadge>
      ))}
    </>
  );
};
