import Image from "next/image";
import { FC } from "react";

import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { RoomCapacity } from "../room-capacity";
import { RoomTags } from "../room-tags";
import { styles } from "./styles";

import {
  generateTableData,
  generateTourDatesText,
} from "@/pagesLayer/oteli/[hotelSlug]/lib/helpers";

import { RoomSelectBlock } from "@/features/hotels";

import { Condition } from "@/shared/lib/condition";
import { getFlightTypeText } from "@/shared/lib/helpers";
import { useGetDeclinations } from "@/shared/lib/hooks";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { TToursCombinedAsRooms } from "@/shared/model/types/tour-content";
import { CustomTooltip } from "@/shared/ui";

import AirplaneIcon from "@/public/icons/common/airplane_outlined.svg";
import InfoIcon from "@/public/icons/common/info.svg";

type TProps = {
  allRooms: TToursCombinedAsRooms[];
};

const TABLE_HEADER = [
  {
    title: "DATES",
  },
  {
    title: "MEALS",
  },
  {
    title: "CAPACITY",
  },
  {
    title: "AIRLINE_2",
  },
  {
    title: "TOUR_OPERATOR",
  },
  {
    title: "OFFERS",
  },
  {
    isSticky: true,
    title: "TOUR_PRICE",
  },
];

const TotalDaysText: FC<{ nights: number; nightsTotal: number }> = ({
  nights,
  nightsTotal,
}) => {
  const { nightsTotalText } = useGetDeclinations({
    nights,
    nightsTotal,
  });

  return <Typography sx={styles.nightsText}>{nightsTotalText}</Typography>;
};

export const AvailableRoomsTable: FC<TProps> = ({ allRooms = [] }) => {
  const t = useTranslations();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const tableData = generateTableData(allRooms);

  return (
    <TableContainer sx={styles.container}>
      <Table sx={styles.wrapper} aria-label="rooms_table">
        <TableHead sx={styles.header}>
          <TableRow>
            {TABLE_HEADER.map((row, index) => (
              <TableCell
                key={index}
                sx={
                  row.isSticky
                    ? { ...styles.cell, ...styles.stickyRightRow }
                    : styles.cell
                }
              >
                {t(row.title)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={row.id} sx={styles.row}>
              <TableCell sx={styles.cell}>
                <TotalDaysText
                  nights={row.dates.nights}
                  nightsTotal={row.dates.nights_total}
                />
                <Typography component="span" sx={styles.datesText}>
                  {generateTourDatesText(
                    row.dates.date_from,
                    row.dates.date_to,
                  )}
                </Typography>
              </TableCell>

              <TableCell sx={{ ...styles.cell, ...styles.mealCell }}>
                <Box sx={styles.mealWrapper}>
                  <Box>
                    <Typography sx={styles.mealTitle}>
                      {row.meal.name}
                    </Typography>
                  </Box>
                  <Condition match={Boolean(row.meal.description)}>
                    <CustomTooltip
                      arrow
                      placement="top"
                      title={row.meal.description}
                    >
                      <Box sx={styles.mealIconWrapper}>
                        <InfoIcon />
                      </Box>
                    </CustomTooltip>
                  </Condition>
                </Box>
              </TableCell>

              <TableCell sx={styles.cell}>
                <RoomCapacity {...row.capacity} />
              </TableCell>

              <TableCell sx={styles.cell}>
                <Box sx={styles.allAirlines}>
                  {row.airlines.length > 0 ? (
                    row.airlines.map((airline) => (
                      <Box key={airline.id} sx={styles.airlineWrapper}>
                        <Condition match={Boolean(airline.icon)}>
                          <Image
                            width={20}
                            height={20}
                            alt={airline.name}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            src={airline.icon!}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                            }}
                          />
                        </Condition>
                        <Condition match={!airline.icon}>
                          <AirplaneIcon width={20} height={20} />
                        </Condition>
                        <Typography>{airline.name}</Typography>
                      </Box>
                    ))
                  ) : (
                    <Box sx={styles.airlineWrapper}>
                      <AirplaneIcon width={20} height={20} />
                      <Typography>
                        {t(getFlightTypeText(row.flightType))}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </TableCell>

              <TableCell sx={styles.cell}>
                <Box sx={styles.cellBox}>
                  {row.tourOperator.logo ? (
                    <Image
                      width={52}
                      height={25}
                      alt={row.tourOperator.name}
                      src={row.tourOperator.logo}
                    />
                  ) : (
                    <Typography>{row.tourOperator.name}</Typography>
                  )}
                </Box>
              </TableCell>

              <TableCell sx={styles.cell}>
                <RoomTags tags={row.tags} />
              </TableCell>

              <TableCell
                scope="row"
                component="th"
                sx={{ ...styles.cell, ...styles.stickyRightRow }}
              >
                <RoomSelectBlock
                  index={index}
                  tourId={row.id}
                  price={row.price.price}
                  fullPrice={row.price.fullPrice}
                  paymentPlan={row.price.paymentPlan}
                  onSelectTour={() => {
                    sendDataLayerEventFn({
                      event: "roomChoise",
                      data: {
                        roomInfo: allRooms.find((room) => room.id === row.id),
                      },
                    });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
